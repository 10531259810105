import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { isBrowser } from "../services/auth"

const apiEntryPoint = () => {
    const env = process.env.NODE_ENV
    if (isBrowser && env === 'production')
        return `/api/graphql`;
    return `http://localhost:4000/api/graphql`;
}

const httpLink = createHttpLink({
    uri: apiEntryPoint(),
})

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("token")
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    }
})

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
})

export default client
