export const isBrowser = typeof window !== "undefined"

/**
 * Se esiste un token, restituisce il token come stringa, sennò null
 * @returns {string|null}
 */
export const getToken = () =>
    isBrowser && window.localStorage.getItem("token")
        ? window.localStorage.getItem("token")
        : null

const setToken = token => {
    if (isBrowser)
        token === null
            ? window.localStorage.removeItem("token")
            : window.localStorage.setItem("token", token)
}

const parseJwt = token => {
    try {
        return JSON.parse(atob(token.split(".")[1]))
    } catch (e) {
        return null
    }
}

export const handleLogin = async ({ mail, password }) => {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            mail: mail,
            password: password,
        }),
    }

    const url = 
        isBrowser && process.env.NODE_ENV === 'production' 
        ? '/api/login' 
        : 'http://localhost:4000/api/login'

    const response = await fetch(url, requestOptions)
    if (response.ok) {
        const jsonValue = await response.json()
        if ("token" in jsonValue) {
            setToken(jsonValue.token)
            return Promise.resolve()
        }
    }
    return Promise.reject()
}

export const isLoggedIn = () => {
    const token = getToken()
    const jwt = parseJwt(token)
    const isJwtNotValid = jwt === null || jwt.exp * 1000 < Date.now()
    return isJwtNotValid ? logout() && false : true
}

export const logout = callback => {
    setToken(null)
    if (typeof callback !== "undefined") callback()
}
